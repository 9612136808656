var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-card", [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.validateBeforeSubmit.apply(null, arguments)
          }
        }
      },
      [
        _c("h1", [_vm._v("Edit Password")]),
        _c("p", { staticClass: "text-muted" }, [
          _vm._v("Enter password and confirmation")
        ]),
        _c(
          "b-row",
          { staticClass: "mb-3" },
          [
            _c(
              "b-col",
              { attrs: { md: "12 mb-2" } },
              [
                _c(
                  "b-input-group",
                  { staticClass: "mb-2" },
                  [
                    _c(
                      "b-input-group-prepend",
                      [
                        _c("b-input-group-text", [
                          _c("i", { staticClass: "fa fa-lock" })
                        ])
                      ],
                      1
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      ref: "password",
                      staticClass: "form-control",
                      attrs: {
                        type: "password",
                        placeholder: "Password",
                        name: "password"
                      },
                      domProps: { value: _vm.password },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.password = $event.target.value
                        }
                      }
                    })
                  ],
                  1
                ),
                _c("i", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("password"),
                      expression: "errors.has('password')"
                    }
                  ],
                  staticClass: "mb-2 fa fa-exclamation-triangle"
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("password"),
                        expression: "errors.has('password')"
                      }
                    ],
                    staticClass: "help is-danger"
                  },
                  [_vm._v(_vm._s(_vm.errors.first("password")))]
                )
              ],
              1
            ),
            _c(
              "b-col",
              { attrs: { md: "12" } },
              [
                _c(
                  "b-input-group",
                  { staticClass: "mb-2" },
                  [
                    _c(
                      "b-input-group-prepend",
                      [
                        _c("b-input-group-text", [
                          _c("i", { staticClass: "fa fa-lock" })
                        ])
                      ],
                      1
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password2,
                          expression: "password2"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|confirmed:password",
                          expression: "'required|confirmed:password'"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "password",
                        placeholder: "Password Confirmation",
                        "data-vv-as": "password",
                        name: "password2"
                      },
                      domProps: { value: _vm.password2 },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.password2 = $event.target.value
                        }
                      }
                    })
                  ],
                  1
                ),
                _c("i", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("password2"),
                      expression: "errors.has('password2')"
                    }
                  ],
                  staticClass: "fa fa-exclamation-triangle"
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("password2"),
                        expression: "errors.has('password2')"
                      }
                    ],
                    staticClass: "help is-danger"
                  },
                  [_vm._v("The passwords must match.")]
                )
              ],
              1
            )
          ],
          1
        ),
        _c("i", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.passworderror,
              expression: "passworderror"
            }
          ],
          staticClass: "fa fa-exclamation-triangle"
        }),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.passworderror,
                expression: "passworderror"
              }
            ],
            staticClass: "help is-danger"
          },
          [_vm._v("Error. Weak password. Try again.")]
        ),
        _c(
          "b-button",
          {
            staticClass: "mt-4",
            attrs: { variant: "success", type: "submit", block: "" }
          },
          [_vm._v("Edit Password")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }