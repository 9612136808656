<template>
  <b-card>
    <form @submit.prevent="validateBeforeSubmit">
        <h1>Edit Password</h1>

        <p class="text-muted">Enter password and confirmation</p>
        <b-row class="mb-3">
          <b-col md="12 mb-2">
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-lock"></i></b-input-group-text>
              </b-input-group-prepend>
              <input type="password" class="form-control" v-model="password" v-validate="'required'" placeholder="Password" name="password" ref="password"/>
            </b-input-group>
            <i v-show="errors.has('password')" class="mb-2 fa fa-exclamation-triangle"></i> <span v-show="errors.has('password')" class="help is-danger">{{ errors.first('password') }}</span>
          </b-col>
          <b-col md="12">
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-lock"></i></b-input-group-text>
              </b-input-group-prepend>
              <input type="password" class="form-control" v-model="password2" v-validate="'required|confirmed:password'" placeholder="Password Confirmation" data-vv-as="password" name="password2" />
            </b-input-group>
            <i v-show="errors.has('password2')" class="fa fa-exclamation-triangle"></i> <span v-show="errors.has('password2')" class="help is-danger">The passwords must match.</span>
          </b-col>
        </b-row>
        <i v-show="passworderror" class="fa fa-exclamation-triangle"></i><span v-show="passworderror" class="help is-danger">Error. Weak password. Try again.</span>

        <b-button variant="success" class="mt-4" type="submit" block>Edit Password</b-button>
      </form>
  </b-card>
</template>

<script>
export default {
  name: 'AdminEditProfile',
  data: function () {
    return {
      password: '',
      password2: '',
      passworderror: false,
    }
  },
  methods: {
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = JSON.stringify({
            password: this.password,
            confirm_password: this.password2,
          })
          this.$http.post('/wts/edit/password', data)
          .then(response => {
            if (response.status === 200) {
              this.$session.set('jwt', response.data.session)
              this.$toasted.show('Password Updated', {icon: 'fa-check', type: 'success', duration: '3000'})
              this.$router.go(-1)
            }
          })
          .catch(error => {
            this.$toasted.show('Error.', {type: 'error', duration: '3000'})
            this.passworderror = true;
          })
          return;
        }
      });
    }
  }
}
</script>
